import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    ReactNode,
} from 'react';
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    XCircleIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
interface ToastContextType {
    addErrorToast: (message: string) => void;
    addWarningToast: (message: string) => void;
    addSuccessToast: (message: string) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

interface ToastProviderProps {
    children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
    const [toasts, setToasts] = useState<{ type: string; message: string }[]>(
        []
    );

    const addToast = (type: string, message: string) => {
        setToasts([{ type, message }]);
    };

    const removeToast = (index: number) => {
        setToasts((prevToasts) => prevToasts.filter((_, i) => i !== index));
    };

    const Toast: React.FC<{ type: string; message: string; index: number }> = ({
        type,
        message,
        index,
    }) => {
        useEffect(() => {
            const timeoutId = setTimeout(() => {
                removeToast(index);
            }, 2000); // Hide after 3 seconds (adjust as needed)

            return () => clearTimeout(timeoutId); // Cleanup the timeout on unmount
        }, [index]);

        return (
            <div
                className={clsx(
                    'min-w-52 h-12 mb-4 p-4 bg-neutral-950 rounded-lg border-b flex gap-2 items-center justify-between',
                    type === 'success'
                        ? 'border-green-400'
                        : type === 'warning'
                          ? 'border-yellow-500'
                          : 'border-red-500'
                )}
            >
                <div className="gap-2 items-center flex">
                    {type === 'success' && (
                        <CheckCircleIcon className="text-green-400 w-5 h-5"></CheckCircleIcon>
                    )}
                    {type === 'warning' && (
                        <ExclamationCircleIcon className="text-yellow-400 w-5 h-5"></ExclamationCircleIcon>
                    )}
                    {type === 'error' && (
                        <XCircleIcon className="text-red-400 w-5 h-5"></XCircleIcon>
                    )}
                    <div className="text-white font-normal">{message}</div>
                </div>
                <div onClick={() => removeToast(index)}>
                    <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        className="cursor-pointer"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M13.1002 4.79883L4.7002 13.1988"
                            stroke="#6C6C6C"
                            strokeWidth="1.4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M4.7002 4.79883L13.1002 13.1988"
                            stroke="#6C6C6C"
                            strokeWidth="1.4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </div>
        );
    };

    return (
        <ToastContext.Provider
            value={{
                addErrorToast: (message) => addToast('error', message),
                addWarningToast: (message) => addToast('warning', message),
                addSuccessToast: (message) => addToast('success', message),
            }}
        >
            {children}
            <div className="absolute sm:fixed top-24 sm:top-4 sm:right-4 inset-x-4 sm:inset-x-auto z-50">
                {toasts.map(({ type, message }, index) => (
                    <Toast
                        key={index}
                        type={type}
                        message={message}
                        index={index}
                    />
                ))}
            </div>
        </ToastContext.Provider>
    );
};

export const useToast = (): ToastContextType => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};
